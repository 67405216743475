@import 'abstracts/mixins';
@import 'abstracts/variables';

@import 'games/login-screen';

.la-login {
	display: grid;
	grid-template-columns: 50% auto 1fr;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background-image: url(Nieuw-Nederland.jpg);
	// background-image: url(Proefstuderen.jpg);
	background-size: cover;
	background-position: center;

	&_la-image {
		position: absolute;
		left: 0;
		right: 0;

		img {
			width: 100%;
		}
	}

	&_blur {
		position: absolute;
		left: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background-color: #3d3c3c8e;
	}

	&_header {
		display: flex;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;
		background-color: var(--color-primary);
		background-color: #e67d22d7;
		height: 20rem;
		z-index: 10;
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);

		.welcome {
			font-size: 4rem;
			color: white;
			text-align: center;
			// margin: 0 8rem 0 12rem;
		}

		.name {
			font-size: 5.5rem;
			color: white;
			text-align: center;
			// margin: 0 8rem 0 12rem;
			font-weight: 600;
		}
	}

	&_form {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		z-index: 10;

		&_card {
			width: 35rem;
			min-height: 42rem;
			background-color: white;
			display: flex;
			flex-direction: column;
			border-radius: var(--border-radius-popup);
			box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
		}

		&_header {
			background-color: var(--color-grey-dark);
			box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
			border: 3px solid white;
			background-image: url(favicon.png);
			background-size: 9rem;
			background-repeat: no-repeat;
			background-position: center;
			width: 16rem;
			height: 16rem;
			border-radius: 50%;
			align-self: center;
			margin-top: -8rem;
			margin-bottom: 3rem;
		}

		&_input {
			display: flex;
			flex-direction: column;
			padding: 2rem 2rem 0 2rem;
			margin-bottom: 5rem;

			.form_label {
				text-align: center;
				font-weight: 600;
				font-size: 1.8rem;
				padding-bottom: 2rem;
			}

			.form_field {
				height: 5.5rem;
				width: 100%;
				font-size: 1.8rem;
				border: 1px solid var(--color-grey-medium);
			}

			.error {
				color: red;
			}
		}

		&_button {
			align-self: flex-end;
			padding: 0 2rem 2rem 2rem;
			width: 100%;
			margin-top: auto;

			.btn--orange {
				width: 100%;
				padding: 1rem;
				text-align: center;
				text-transform: uppercase;
				font-size: 1.6rem;
				font-weight: 600;
			}

			.btn--grey {
				width: 100%;
				padding: 1rem;
				text-align: center;
				text-transform: uppercase;
				font-size: 1.6rem;
				font-weight: 600;
			}
		}

		&_contact {
			text-align: center;
			font-size: 1.4rem;
			padding-bottom: 0.8rem;

			.contact {
				text-decoration: underline;
				color: #e86d02;
			}

			.powered {
				font-weight: 500;
				padding-top: 3rem;
				text-align: left;
				padding-left: 1rem;
				color: var(--color-grey-medium);
				font-size: 1.2rem;

				a {
					text-decoration: underline;
				}
			}
		}
	}

	&_name-bar {
		height: 20rem;
		width: 10rem;
		background-color: #e67d22d7;
		z-index: 10;
		box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
	}
}
