:root {
	--font-default: 'Roboto', sans-serif;

	//auteursomgeving
	--color-primary: #e67e22;
	--color-primary-light: #f8d3c0;
	--color-primary-very-light: #feece3;

	--color-item-select: #612103;

	--color-blue: #36d2da;
	--color-blue-light: #bae5e7;
	--color-yellow: #ffd56d;
	--color-yellow-light: #f7e6bb;
	--color-green: #41bd55;
	--color-green-light: #7be98d;

	--color-grey-very-very-light: #f6f6f6;
	--color-grey-very-light: #ebe8e8;
	--color-grey-transparant: rgba(235, 232, 232, 0.5);
	--color-grey-light: #c4c4c4;
	--color-grey-medium: #8b8686;
	--color-grey-dark: #626161;
	--color-grey-very-dark: #3d3c3c;

	--border-radius-popup: 15px;

	//spelersomgeving
	--color-btn-blue: var(--color-blue);
	--color-btn-green: #009378;
	--color-btn-grey: var(--color-grey-very-light);
	--color-btn-orange: var(--color-primary);
	--color-btn-red: rgb(233, 86, 86);
	--color-btn-yellow: var(--color-yellow);
	--color-btn-dark: var(--color-very-dark);

	--color-btn-green-disabled: #c7d1d0;
	--color-btn-blue-disabled: #cde9ea;
	--color-btn-grey-disabled: #f0f0f0;
	--color-btn-orange-disabled: #feece3;
	--color-btn-red-disabled: rgb(235, 208, 208);
	--color-btn-yellow-disabled: #f1ecdd;
	--color-btn-dark-disabled: var(--color-grey);

	--color-failure: rgb(249, 207, 207);
	--color-success: #c9fcf7;
}

// Z-INDEX
// Popup				9999
// Action details		9000
